import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const useStyles = makeStyles()({
    root: {},
    notchedOutline: {
        border: 'none'
    },
    inputRoot: {
        borderRadius: 16,
        fontSize: 16,
        color: '#232323',
        background: '#F8F8F8'
    },
    inputElement: {
        height: 16,
        padding: '29px 16px 13px 16px'
    },
    inputLabelRoot: {
        color: '#8E8E8E !important',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '16px',
        transform: 'translate(14px, 20px) scale(1)'
    },
    inputLabelShrink: {
        transform: 'translate(14px, 12px) scale(0.75)',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '12px'
    }
});

const TextField: React.FC<TextFieldProps> = ({ className, InputProps, InputLabelProps, ...restProps }) => {
    const { classes, cx } = useStyles();
    return (
        <MuiTextField
            variant="outlined"
            className={cx(classes.root, className)}
            InputProps={{
                ...InputProps,
                classes: {
                    root: classes.inputRoot,
                    notchedOutline: classes.notchedOutline,
                    input: classes.inputElement,
                    ...InputProps?.classes
                }
            }}
            InputLabelProps={{
                ...InputLabelProps,
                classes: { root: classes.inputLabelRoot, shrink: classes.inputLabelShrink, ...InputLabelProps?.classes }
            }}
            {...restProps}
        />
    );
};

export default TextField;
